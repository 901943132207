@use "bourbon";

body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
}

.container {
    
    transform: translate(-50%, -50%);
}
.nav-toggler{
    font-size: 1.5rem;
    color: white;
    float: right;

    @media screen and (min-width:1024px){
        display:none;
     
     }
    

}
.nav-toggler:hover{
    color: #75610a;
}
.btn {
    color: #fff;
    
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    
    span {
        font-family: 'Roboto', sans-serif;
        position: relative;
        z-index: 100;
    }
    
    &:before, &:after {
        content:'';
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
    
    &:before {
        transform: translate3d(-100%, 0, 0);
        background-color: rgba(0, 0, 0, 0.425);
        text-decoration: underline;
        
        
        transition: transform 300ms ease-in-cubic;
    }
    
    &:after {
        color:#fff;
        
        transform: translate3d(100%, 0, 0);
        
        transition: transform 300ms 300ms cubic-bezier(.16,.73,.58,.62);
    }
    
    &:hover {
        &:before {
            transform: translate3d(0,0,0);
        }
        
        &:after {
            transform: translate3d(0,0,0);
        }
    }

}