.News {
    display: flex;
    justify-content: space-around;
    font-family: sans-serif;
    text-align: center;
    padding-top: 40px;
    height: 100vh;
   
    
  }
@media only screen and (max-width: 960px) {
    .News{
      flex-direction: column;
      width: 100vw;
    }
  }
  
  .time {
    font-size: 32px;
    
  }
.news{
  overflow-y: scroll;
  overflow-x: hidden;
}