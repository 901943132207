
.contactbackground{
  background: no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 900px;
  overflow: hidden;

}
@media screen {
  
}
@media only screen and (min-width: 1024px) {
  #contact1{
    margin-left: 40rem;
    margin-right: 40rem;
    
  }
}
